import { CardProps, CardMediaProps, CardActionAreaProps } from '@mui/material'
import React, { ReactElement } from 'react'
import LeafBaseMediaCard from '../LeafBaseMediaCard'

interface LeafFixedMediaCardProps extends CardProps {
    media: ReactElement<CardMediaProps>
    actionAreaProps?: CardActionAreaProps
    alignMedia?: 'top' | 'left'
    mediaWidth?: 'md' | 'lg' | undefined
    // mediaHeight?: string
}

enum mediaWidthValues {
    md = '33.33%',
    lg = '50%'
}

// mediaWidth and mediaHeight are locked to start, we may allow custom/enum options in the future, but not yet.
const LeafFixedMediaCard: React.FC<LeafFixedMediaCardProps> = ({
    media,
    actionAreaProps,
    alignMedia = 'left',
    mediaWidth = 'lg',
    // mediaHeight = '200px',
    ...props
}) => {
    const mediaWidthValue = mediaWidthValues[mediaWidth]
    const mediaHeight = '200px'
    return (
        <LeafBaseMediaCard
            media={media}
            actionAreaProps={actionAreaProps}
            alignMedia={alignMedia}
            mediaHeight={mediaHeight}
            mediaWidth={mediaWidthValue}
            {...props}
        >
            {props.children}
        </LeafBaseMediaCard>
    )
}

export default LeafFixedMediaCard
