import {
    Dialog,
    DialogContent,
    type DialogProps,
    DialogTitle,
    useMediaQuery
} from '@mui/material'
import React, {
    cloneElement,
    isValidElement,
    type ReactElement,
    type ReactNode,
    useCallback,
    useMemo,
    useState,
    Children
} from 'react'
import { useTheme } from '../../../../hooks'
import LeafCloseButton from '../../Buttons/LeafCloseButton'
import CoreTypography from '../../CoreTypography'

const CONTENT_HEIGHT_THRESHOLD_SM = 550
const MAX_DIALOG_HEIGHT = '800px'
const MAX_DIALOG_WIDTH_MEDIUM = '600px'
const MAX_DIALOG_WIDTH_LARGE = '1000px'

// Hacky way to add a ref and track the content size of the DialogContent, so we can determine if we need to make the Dialog full screen
export function takeChildrenAndAddRefToDialogContent(
    children: ReactNode,
    onDialogContentChange: (ref: HTMLDivElement) => void
) {
    return Children.map(children, (child) => {
        if (isValidElement(child) && child.type === DialogContent) {
            return cloneElement(child as ReactElement, {
                ref: onDialogContentChange
            })
        }
        return child
    })
}

interface IMaybeOverrideProps {
    contentHeight: number
    hasSmDown: boolean
    props: DialogProps
}
export function maybeOverrideProps({
    contentHeight,
    hasSmDown,
    props
}: IMaybeOverrideProps) {
    // Return early as the Dialog already has the fullScreen prop or the screen is not small
    const alreadyHasFullscreenProp = props.fullScreen
    if (alreadyHasFullscreenProp || !hasSmDown) {
        return props
    }
    const hasEnoughContent = contentHeight >= CONTENT_HEIGHT_THRESHOLD_SM
    if (hasEnoughContent) {
        return {
            ...props,
            fullScreen: true
        }
    }
    return props
}

export interface LeafDialogInternalProps extends DialogProps {
    hideCloseButton?: boolean
    dialogTitle?: ReactElement | string
    onClose?: () => void
    isConfirmationDialog?: boolean
}

export const LeafDialogInternal: React.FC<LeafDialogInternalProps> = ({
    hideCloseButton = false,
    dialogTitle,
    onClose,
    isConfirmationDialog,
    ...props
}) => {
    const theme = useTheme()
    const { sx, children, ...otherProps } = props

    const [contentHeight, setContentHeight] = useState<number>(0)
    const hasSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const onDialogContentChange = useCallback(
        (ref: HTMLDivElement) => {
            if (ref) {
                setContentHeight(ref.scrollHeight)
            }
        },
        [children]
    )

    // Modify the Dialog to full screen if the content is large enough and on mobile
    const transformedProps = useMemo(() => {
        return maybeOverrideProps({
            contentHeight,
            hasSmDown,
            props: otherProps
        })
    }, [contentHeight, hasSmDown, otherProps])

    const childrenWithRefOnDialogContent = useMemo(
        () =>
            takeChildrenAndAddRefToDialogContent(
                children,
                onDialogContentChange
            ),
        [children, onDialogContentChange]
    )
    const isFullscreen = transformedProps.fullScreen

    return (
        <Dialog
            onClose={onClose}
            {...transformedProps}
            sx={{
                ...sx,
                '.MuiDialog-paper': {
                    py: 1.5,
                    px: 3,
                    [theme.breakpoints.up('md')]: {
                        ...(!isFullscreen && {
                            maxWidth: MAX_DIALOG_WIDTH_MEDIUM
                        })
                    },
                    [theme.breakpoints.up('lg')]: {
                        ...(!isFullscreen && {
                            minWidth: MAX_DIALOG_WIDTH_MEDIUM,
                            maxWidth: isConfirmationDialog
                                ? MAX_DIALOG_WIDTH_MEDIUM
                                : MAX_DIALOG_WIDTH_LARGE
                        })
                    }
                },
                '.MuiDialogTitle-root': {
                    py: 1.5,
                    px: 0
                },
                '.MuiDialogContent-root': {
                    py: 1.5,
                    px: 0,
                    [theme.breakpoints.up('md')]: {
                        ...(!isFullscreen && {
                            maxHeight: MAX_DIALOG_HEIGHT
                        })
                    }
                },
                '.MuiDialogActions-root': {
                    py: 1.5,
                    px: 0,
                    justifyContent: 'flex-end',
                    '> :not(:first-of-type)': {
                        ml: 2
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexFlow: 'wrap',
                        '> :not(:first-of-type)': {
                            ml: 0,
                            mt: 2
                        },
                        '.MuiButton-root': {
                            width: '100%'
                        }
                    }
                }
            }}
        >
            <DialogTitle
                component="div"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <CoreTypography
                    variant="h3"
                    color="text.primary"
                    component="h2"
                    id={props['aria-labelledby']}
                >
                    {dialogTitle}
                </CoreTypography>

                {!hideCloseButton && (
                    <LeafCloseButton
                        onClick={() => onClose()}
                        sx={{ ml: 2, alignSelf: 'flex-start' }}
                    />
                )}
            </DialogTitle>
            {childrenWithRefOnDialogContent}
        </Dialog>
    )
}
export default LeafDialogInternal
