import { Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import React, {
    Children,
    useCallback,
    useMemo,
    type ReactElement,
    type ReactNode
} from 'react'
import { FormattedMessage, useIntl, type MessageDescriptor } from 'react-intl'
import { customTypography } from '../../../theme'

export interface CoreTypographyProps
    extends TypographyProps<
        React.ElementType,
        { component?: React.ElementType }
    > {
    uppercase?: boolean
    customVariant?:
        | 'buttonLarge'
        /* @deprecated use buttonLarge or buttonSmall instead */
        | 'buttonNormal'
        | 'buttonSmall'
        | 'form'
        | 'formBody'
        | 'stat1'
        | 'stat2'
        | 'stat3'
        | 'display1'
        | 'display2'
        | 'display3'
        | 'display4'
        | 'display5'
}

export const CoreTypography: React.FC<CoreTypographyProps> = ({
    sx,
    variant,
    customVariant,
    children,
    uppercase,
    ...otherProps
}) => {
    const variantFromTheme = customVariant
        ? customTypography[customVariant]
        : undefined

    const customSx = useMemo(() => {
        return sx ? { ...sx, ...variantFromTheme } : variantFromTheme
    }, [sx, variantFromTheme])

    const { formatMessage, locale } = useIntl()

    const uppercaseVariant = uppercase || variant === 'overline'

    const renderUppercaseChilren = useCallback(
        (children: ReactNode) => {
            return Children.toArray(children).map(
                (child: string | ReactElement<any>) => {
                    if (child === null) return null

                    if (typeof child === 'string') {
                        return child.toLocaleUpperCase(locale)
                    }

                    if (child.type === FormattedMessage) {
                        const messageDesc: MessageDescriptor = {
                            id: child.props?.id,
                            description: child.props?.description,
                            defaultMessage: child.props.defaultMessage
                        }

                        const formattedMessageChunks = Children.toArray(
                            formatMessage(messageDesc, child.props?.values)
                        )

                        return formattedMessageChunks.map((messageChunk) =>
                            typeof messageChunk === 'string'
                                ? messageChunk.toLocaleUpperCase(locale)
                                : messageChunk
                        )
                    }

                    return child
                }
            )
        },
        [formatMessage, locale]
    )

    return (
        <Typography
            sx={customSx}
            variant={customVariant ? undefined : variant}
            {...otherProps}
        >
            {uppercaseVariant ? renderUppercaseChilren(children) : children}
        </Typography>
    )
}

export default CoreTypography
