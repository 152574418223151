import {
    Box,
    Button,
    ButtonProps,
    Grid,
    GridProps,
    Stack,
    SxProps,
    Theme,
    useMediaQuery,
    useTheme
} from '@mui/material'
import debounce from 'lodash.debounce'
import React, {
    memo,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { IconButton } from '../../../index'
import { defineMessages, useIntl } from 'react-intl'
import CoreTypography from '../../CoreTypography'
import LeafIcon from '../../../foundations/icons'

enum LeafCarouselNavButtonDirection {
    BACK = -1,
    FORWARD = 1
}

export interface LeafCarouselProps {
    children: ReactElement[]
    ariaLabel: string
    contentSize?: 1 | 2 | 3 | 4
    title?: string | ReactElement
    alignNavigationBottom?: boolean
    carouselCabooseProps?: ButtonProps
    sx?: SxProps<Theme>
}

interface CarouselChildProps extends GridProps {
    childIndex: number
}

const messages = defineMessages({
    carouselRoleDescription: {
        defaultMessage: 'Carousel',
        description:
            'Descriptive text for the visually impaired to indicate the presence of a carousel.'
    },
    backButtonAriaLabelPlural: {
        defaultMessage: 'Show previous carousel items',
        description:
            'Descriptive text for the visually impaired to show previous items in the carousel.'
    },
    forwardButtonAriaLabelPlural: {
        defaultMessage: 'Show next carousel items',
        description:
            'Descriptive text for the visually impaired to show next items in the carousel.'
    },
    backButtonAriaLabelSingular: {
        defaultMessage: 'Show previous carousel item',
        description:
            'Descriptive text for the visually impaired to show previous item in the carousel.'
    },
    forwardButtonAriaLabelSingular: {
        defaultMessage: 'Show next carousel item',
        description:
            'Descriptive text for the visually impaired to show next item in the carousel.'
    },
    skipToEndCarouselLinkLabel: {
        defaultMessage: 'Skip to end of carousel',
        description: 'Label for carousel skip to end button.'
    },
    viewAll: {
        defaultMessage: 'View all',
        description: 'Button text to see all options'
    }
})

const CarouselTitle = ({ title }: { title: string | ReactElement }) => {
    if (title) {
        if (typeof title === 'string') {
            return <CoreTypography variant={'h3'}>{title}</CoreTypography>
        } else {
            return title
        }
    } else {
        return null
    }
}

const LeafCarousel: React.FC<LeafCarouselProps> = ({
    children,
    ariaLabel,
    contentSize = 3,
    title,
    alignNavigationBottom = false,
    carouselCabooseProps,
    sx
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const carouselRef = useRef(null)
    const carouselFirstChildRef = useRef(null)
    const carouselLastChildRef = useRef(null)
    const mdDown =
        useMediaQuery(theme.breakpoints.down('md')) || alignNavigationBottom

    const [carouselWidth, setCarouselWidth] = useState<number>(0)
    const [carouselScrollLeft, setCarouselScrollLeft] = useState<number>(0)
    const [disableBackButton, setDisableBackButton] = useState<boolean>(true)
    const [disableForwardButton, setDisableForwardButton] =
        useState<boolean>(false)
    const [hideButtons, setHideButtons] = useState<boolean>(
        children.length <= contentSize
    )

    // visibility props to determine when to show/hide content from keyboard navigation users
    const [visibleChildWindow, setVisibleChildWindow] = useState<{
        min: number
        max: number
    }>({
        min: 0,
        max: contentSize - 1
    })
    const [hideNonVisibleContent, setHideNonVisibleContent] =
        useState<boolean>(true)

    const setButtonVisibility = () => {
        const carouselPos = carouselRef.current.getBoundingClientRect()

        setDisableBackButton(
            carouselFirstChildRef.current.getBoundingClientRect()?.left >=
                carouselPos.left
        )

        setDisableForwardButton(
            carouselLastChildRef.current.getBoundingClientRect()?.left -
                carouselPos.right <=
                1
        )
    }

    const calculateVisibleChildWindow = () => {
        const allChildren = Array.from(
            carouselRef.current.getElementsByClassName('carouselChild')
        )

        //find first carousel child in view, off by maximum 1 px
        const activeTabIndexStart = allChildren.findIndex(
            (child: HTMLElement) => {
                return (
                    Math.abs(
                        child.getBoundingClientRect().left -
                            carouselRef.current.getBoundingClientRect().left
                    ) < 1
                )
            }
        )

        setVisibleChildWindow({
            min: activeTabIndexStart,
            max: activeTabIndexStart + contentSize - 1
        })
    }

    let scrollEndTimer
    // set button visibility and active child index window on scroll end
    const handleScrollEnd = () => {
        clearTimeout(scrollEndTimer)
        scrollEndTimer = setTimeout(() => {
            if (
                carouselRef.current &&
                carouselFirstChildRef.current &&
                carouselLastChildRef.current
            ) {
                setButtonVisibility()
                setHideNonVisibleContent(true)
                calculateVisibleChildWindow()
            }
        }, 100)
    }

    useEffect(() => {
        setCarouselWidth(carouselRef.current.clientWidth)
        setButtonVisibility()
    }, [carouselRef?.current?.clientWidth])

    // reset carousel to start
    const restartCarousel = () => {
        if (carouselRef && carouselRef.current) {
            setCarouselWidth(carouselRef.current.clientWidth)
            carouselRef.current.scroll({
                left: 0,
                behavior: 'auto'
            })
            setCarouselScrollLeft(0)
        }
        setDisableBackButton(true)
        setDisableForwardButton(false)
        setVisibleChildWindow({ min: 0, max: contentSize - 1 })
    }

    useEffect(() => {
        setHideButtons(children.length <= contentSize)
    }, [contentSize, children.length])

    // on children change
    useEffect(() => {
        restartCarousel()
    }, [children])

    // restartCarousel on resize
    useEffect(() => {
        window.addEventListener('resize', debounce(restartCarousel, 100))
        return () => window?.removeEventListener('resize', restartCarousel)
    }, [restartCarousel])

    // set conditional fields on scroll end
    useEffect(() => {
        if (carouselRef && carouselRef.current) {
            carouselRef.current.addEventListener(
                'scroll',
                handleScrollEnd,
                false
            )
            return function cleanup() {
                carouselRef.current?.removeEventListener(
                    'scroll',
                    handleScrollEnd,
                    false
                )
            }
        }
    }, [])

    const slide = (direction: LeafCarouselNavButtonDirection) => {
        setHideNonVisibleContent(false)
        const scrollLeft = carouselScrollLeft + carouselWidth * direction

        setCarouselScrollLeft(scrollLeft)

        setTimeout(() => {
            carouselRef.current.scrollTo({
                left: scrollLeft,
                behavior: prefersReducedMotion ? 'auto' : 'smooth'
            })
        }, 100)
    }

    const CarouselChild = useMemo(
        () =>
            ({ childIndex, ...props }: CarouselChildProps) => {
                return (
                    <Grid
                        item
                        xs={12 / contentSize}
                        className={'carouselChild'}
                        aria-hidden={
                            childIndex > visibleChildWindow.max ||
                            childIndex < visibleChildWindow.min
                        }
                        visibility={
                            hideNonVisibleContent &&
                            (childIndex > visibleChildWindow.max ||
                                childIndex < visibleChildWindow.min)
                                ? 'hidden'
                                : 'visible'
                        }
                        flex={'none'}
                        display={'flex'}
                        justifyContent={'center'}
                        padding={theme.spacing(2, mdDown ? 1 : 2)}
                        width={'100%'}
                    >
                        {props.children}
                    </Grid>
                )
            },
        [
            contentSize,
            hideNonVisibleContent,
            mdDown,
            theme,
            visibleChildWindow.max,
            visibleChildWindow.min
        ]
    )

    return (
        <>
            {mdDown && <CarouselTitle title={title} />}
            <Stack
                spacing={1}
                direction={mdDown ? 'column-reverse' : 'column'}
                position={'relative'}
                sx={sx}
            >
                <Button
                    color="primary"
                    variant="contained"
                    href={'#end-of-carousel-' + ariaLabel}
                    sx={{
                        zIndex: 0,
                        position: 'absolute',
                        top: 0,
                        left: -100000,
                        margin: theme.spacing(1),
                        '&:focus-visible': {
                            left: 0,
                            zIndex: 1200
                        }
                    }}
                >
                    <CoreTypography customVariant="buttonSmall">
                        {intl.formatMessage(
                            messages.skipToEndCarouselLinkLabel
                        )}
                    </CoreTypography>
                </Button>
                <Stack
                    direction={'row'}
                    spacing={3}
                    width={'100%'}
                    justifyContent={mdDown ? 'center' : 'flex-end'}
                    px={'1px'}
                >
                    {!mdDown && (
                        <Box width={'100%'} alignSelf={'center'}>
                            <CarouselTitle title={title} />
                        </Box>
                    )}
                    {!hideButtons && (
                        <>
                            <IconButton
                                color="info"
                                variant="contained"
                                disableElevation={false}
                                disabled={disableBackButton}
                                onClick={() =>
                                    slide(LeafCarouselNavButtonDirection.BACK)
                                }
                                aria-label={intl.formatMessage(
                                    contentSize === 1
                                        ? messages.backButtonAriaLabelSingular
                                        : messages.backButtonAriaLabelPlural
                                )}
                            >
                                <LeafIcon icon={'arrow-left'} />
                            </IconButton>
                            <IconButton
                                color="info"
                                variant="contained"
                                disableElevation={false}
                                disabled={disableForwardButton}
                                onClick={() =>
                                    slide(
                                        LeafCarouselNavButtonDirection.FORWARD
                                    )
                                }
                                aria-label={intl.formatMessage(
                                    contentSize === 1
                                        ? messages.forwardButtonAriaLabelSingular
                                        : messages.forwardButtonAriaLabelPlural
                                )}
                            >
                                <LeafIcon icon={'arrow-right'} />
                            </IconButton>
                        </>
                    )}
                </Stack>
                <Stack direction={'row'}>
                    <Box
                        mx={`-${mdDown ? 7 : 15}px !important`}
                        width={`calc(100% + ${mdDown ? 14 : 30}px) !important`}
                        overflow={'hidden'}
                    >
                        <Grid
                            container
                            spacing={0}
                            ref={carouselRef}
                            overflow={'hidden'}
                            role={'region'}
                            aria-label={ariaLabel}
                            aria-roledescription={intl.formatMessage(
                                messages.carouselRoleDescription
                            )}
                            sx={{
                                flexFlow: 'nowrap'
                            }}
                        >
                            <span ref={carouselFirstChildRef} />
                            {children.map((child, i) => (
                                <CarouselChild
                                    key={`${title}-${i}`}
                                    childIndex={i}
                                >
                                    {child}
                                </CarouselChild>
                            ))}
                            {carouselCabooseProps && (
                                <CarouselChild childIndex={children.length}>
                                    <Box alignSelf={'center'}>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            endIcon={
                                                <LeafIcon
                                                    icon={'arrow-right'}
                                                    fontSize={'small'}
                                                />
                                            }
                                            {...carouselCabooseProps}
                                        >
                                            <CoreTypography
                                                customVariant={'buttonLarge'}
                                            >
                                                {carouselCabooseProps.title ||
                                                    intl.formatMessage(
                                                        messages.viewAll
                                                    )}
                                            </CoreTypography>
                                        </Button>
                                    </Box>
                                </CarouselChild>
                            )}
                            <span ref={carouselLastChildRef} />
                        </Grid>
                    </Box>
                    <span id={'end-of-carousel-' + ariaLabel} />
                </Stack>
            </Stack>
        </>
    )
}

export default memo(LeafCarousel)
