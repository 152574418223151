import React from 'react'
import LeafDialogInternal, {
    type LeafDialogInternalProps
} from '../LeafDialogInternal'

export type LeafDialogProps = Omit<
    LeafDialogInternalProps,
    'isConfirmationDialog'
>

export const LeafDialog: React.FC<LeafDialogProps> = (props) => {
    return <LeafDialogInternal {...props} isConfirmationDialog={false} />
}
export default LeafDialog
