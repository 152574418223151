import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'

import LeafPrivacyPolicyLink from '../../../elements/Links/LeafPolicyLinks/LeafPrivacyPolicyLink/'
import LeafToSLink from '../../../elements/Links/LeafPolicyLinks/LeafToSLink/'

import { CheckboxConsentProps } from '../'

const Row = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
`

const messages = defineMessages({
    generalDataCheckboxText: {
        // defaultMessage: '<b>General Data:</b> I agree to the collection and use of my personal information (e.g., name, email, engagement metrics) for account setup, service delivery, and communication as described in the <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <ToS>Terms of Service.</ToS> (Required)',
        defaultMessage:
            '<b>일반 데이터:</b> 본인의 개인정보(이름, 이메일 주소, 참여 수치 등)를 <PrivacyPolicy>개인정보 처리 방침</PrivacyPolicy> 및 <ToS>서비스 약관</ToS>에 따라 계정 설정, 서비스 전달과 소통 방침을 위해 수집하고 활용하는 데에 동의합니다. (필수)',
        description: ''
    },
    sensitiveDataCheckboxText: {
        //defaultMessage: '<b>Sensitive Data:</b> I consent to the collection and use of my sensitive personal information (e.g., health and well-being data) for personalized recommendations and insights, as described in the <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <ToS>Terms of Service.</ToS> (Required)',
        defaultMessage:
            '<b>민감한 정보:</b> <PrivacyPolicy>개인정보 처리 방침</PrivacyPolicy> 및 <ToS>서비스 약관</ToS>에 설명된 대로, 개인화된 추천 사항과 인사이트를 얻기 위해 본인의 민감한 정보(건강, 웰빙 관련 데이터 등)를 수집하고 활용하는 것에 동의합니다. (필수)',
        description: ''
    },
    marketingCommunicationsCheckboxText: {
        //defaultMessage: '<b>Marketing Communications:</b> I agree to receive marketing communications and updates from Thrive.',
        defaultMessage:
            '<b>마케팅 커뮤니케이션:</b> Thrive로부터 마케팅 관련 정보와 업데이트를 수신하는 것에 동의합니다.',
        description: ''
    }
})

export type APACConsentState = {
    generalData?: boolean
    sensitiveData?: boolean
    marketingCommunications?: boolean
}

export type APACConsentProps = {
    defaultValue?: boolean
    onAccepted: CheckboxConsentProps['onAccepted']
}

export const APACConsent: React.FC<APACConsentProps> = ({
    defaultValue = false,
    onAccepted
}) => {
    const intl = useIntl()
    const [state, setState] = useState<APACConsentState>({
        generalData: defaultValue,
        sensitiveData: defaultValue,
        marketingCommunications: defaultValue
    })

    const handleCheckboxChange =
        (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = { ...state, [field]: event.target.checked }
            const isComplete = newState.generalData && newState.sensitiveData

            setState(newState)
            onAccepted(isComplete, newState)
        }

    return (
        <div>
            <Row>
                <Checkbox
                    required
                    id="thrive-consent-general-data"
                    checked={state.generalData}
                    onChange={handleCheckboxChange('generalData')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-general-data"
                >
                    {intl.formatMessage(messages.generalDataCheckboxText, {
                        b: (chunks) => <b>{chunks}</b>,
                        PrivacyPolicy: () => <LeafPrivacyPolicyLink />,
                        ToS: () => <LeafToSLink />
                    })}
                </Typography>
            </Row>

            <Row>
                <Checkbox
                    required
                    id="thrive-consent-sensitive-data"
                    checked={state.sensitiveData}
                    onChange={handleCheckboxChange('sensitiveData')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-sensitive-data"
                >
                    {intl.formatMessage(messages.sensitiveDataCheckboxText, {
                        b: (chunks) => <b>{chunks}</b>,
                        PrivacyPolicy: () => <LeafPrivacyPolicyLink />,
                        ToS: () => <LeafToSLink />
                    })}
                </Typography>
            </Row>

            <Row>
                <Checkbox
                    id="thrive-consent-marketing-communication"
                    checked={state.marketingCommunications}
                    onChange={handleCheckboxChange('marketingCommunications')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-marketing-communication"
                >
                    {intl.formatMessage(
                        messages.marketingCommunicationsCheckboxText,
                        {
                            b: (chunks) => <b>{chunks}</b>
                        }
                    )}
                </Typography>
            </Row>
        </div>
    )
}
