import { Button, styled } from '@mui/material'
import { HEIGHTS, BORDER_RADIUS } from '../../../../theme/constants'

export const IconButton: any = styled(Button)({
    minHeight: HEIGHTS.MEDIUM_COMPONENT_HEIGHT + '!important',
    minWidth: HEIGHTS.MEDIUM_COMPONENT_HEIGHT + '!important',
    maxHeight: HEIGHTS.MEDIUM_COMPONENT_HEIGHT + '!important',
    maxWidth: HEIGHTS.MEDIUM_COMPONENT_HEIGHT + '!important',
    borderRadius: BORDER_RADIUS.BUTTON,
    marginLeft: 1
})

export default IconButton
