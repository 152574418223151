/* eslint-disable @thriveglobal/sentence-case/sentence-case */
import { type TypographyOptions } from '@mui/material/styles/createTypography'
import { FONTS } from './constants'

const TYPOGRAPHY_FONT_FAMILY = Object.values(FONTS)
    .map((font) => `'${font.fontFamily}'`)
    .join(', ')

export const defaultTypography: TypographyOptions = {
    fontFamily: TYPOGRAPHY_FONT_FAMILY,
    h1: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        lineHeight: 1.08,
        letterSpacing: '-.15rem',
        '@media (max-width:600px)': {
            fontSize: '3.2rem',
            lineHeight: 1.25,
            letterSpacing: '-0.005rem'
        }
    },
    h2: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem',
        '@media (max-width:600px)': {
            fontSize: '2.8rem',
            lineHeight: 1.21
        }
    },
    h3: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        lineHeight: 1.17,
        letterSpacing: '-0.0015rem'
    },
    h4: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2rem',
        lineHeight: 1.2,
        letterSpacing: '-0.0015rem'
    },
    h5: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontSize: '1.6rem',
            lineHeight: 1.37
        }
    },
    h6: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.29,
        letterSpacing: '0.01rem',
        '@media (max-width:600px)': {
            fontSize: '1.2rem',
            lineHeight: 1.17
        }
    },
    caption: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            lineHeight: 1.5
        }
    },
    overline: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.43,
        letterSpacing: '.12rem',
        '@media (max-width:600px)': {
            fontSize: '1.2rem',
            lineHeight: 1.5
        }
    },
    body1: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: 1.37,
        letterSpacing: '-0.0015rem'
    },
    body2: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.43,
        letterSpacing: '-0.0015rem'
    }
}

export const customTypography = {
    stat1: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '8rem',
        lineHeight: 0.94,
        letterSpacing: '-.01rem'
    },
    stat2: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '5.6rem',
        lineHeight: 1.14,
        letterSpacing: '-0.005rem'
    },
    stat3: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display1: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '9.6rem',
        lineHeight: 1.04,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontSize: '6.7rem',
            lineHeight: 1.1
        }
    },
    display2: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        lineHeight: 1.17,
        letterSpacing: '-0.005rem'
    },
    display3: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display4: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        lineHeight: 1.19,
        letterSpacing: '-0.0015rem'
    },
    display5: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.015rem'
    },
    buttonLarge: {
        fontFamily: FONTS.GT_AMERICA_MD.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    /** @deprecated */
    buttonNormal: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: 1.5
    },
    buttonSmall: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.5
    },
    form: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    formBody: {
        fontFamily: FONTS.GT_AMERICA_RG.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2.8rem',
        lineHeight: 1.29,
        letterSpacing: '-0.005rem'
    }
}
