import { Chip, type ChipProps } from '@mui/material'
import CoreTypography from '../../CoreTypography'
import LeafIcon from '../../../foundations/icons'
import React, { useMemo } from 'react'

export interface LeafChipProps extends ChipProps {
    selectable?: boolean
    selected?: boolean
}

const LeafChip: React.FC<LeafChipProps> = ({
    selectable = false,
    selected = false,
    ...props
}) => {
    const { icon, variant, label, ...otherProps } = props

    const chipIcon = useMemo(() => {
        if (!selectable) {
            return icon
        }

        if (selected) {
            return <LeafIcon icon={'check'} fontSize={'small'} />
        } else {
            return <LeafIcon icon={'plus'} fontSize={'small'} />
        }
    }, [selectable, selected, icon])

    return (
        <Chip
            variant={selectable ? (selected ? 'filled' : 'outlined') : variant}
            icon={chipIcon}
            label={
                <CoreTypography variant={'overline'}>{label}</CoreTypography>
            }
            {...otherProps}
        />
    )
}

export default LeafChip
