export enum THEME {
    THRIVE = 'THRIVE',
    DARK = 'DARK',
    HIGH_CONTRAST = 'HIGH_CONTRAST',
    NATURE = 'NATURE',
    CAMPBWELL = 'CAMP_B-WELL',
    DEMO = 'DEMO'
}

export const THEMES = {
    THRIVE: THEME.THRIVE,
    HIGH_CONTRAST: THEME.HIGH_CONTRAST,
    DARK: THEME.DARK,
    DEMO: THEME.DEMO
}

export const MSTEAMS_THEMES = {
    default: THEME.THRIVE,
    dark: THEME.DARK,
    contrast: THEME.HIGH_CONTRAST
}

export const WIDTHS = {
    MIN_BUTTON_WIDTH: '72px'
}

export const HEIGHTS = {
    LARGE_COMPONENT_HEIGHT: '58px',
    MEDIUM_COMPONENT_HEIGHT: '44px'
}

export const BORDER_RADIUS = {
    DEFAULT: '16px',
    BUTTON: '24px',
    CHIP: '8px'
} as const

export const Z_INDEX = {
    BELOW_NAV: 1099
}

export const FONTS = {
    // Can be removed once all instances of GT Super Display are removed from apps
    // https://sourcegraph.thriveglobal.engineering/search?q=context%3Aglobal+%22GT+Super+Display%22&patternType=keyword&sm=0
    /** @deprecated */
    GT_SUPER_DISPLAY: {
        fontFamily: 'GT Super Display',
        url: 'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-Super-Full-desktop/GT-Super-Display-Medium.otf'
    },
    GT_AMERICA_MD: {
        fontFamily: 'GT America Md',
        url: 'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Medium.otf'
    },
    GT_AMERICA_RG: {
        fontFamily: 'GT America Rg',
        url: 'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Regular.otf'
    }
} as const
